$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.min.css';
@import 'ngx-toastr/toastr';
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-sharebuttons/themes/circles';
@import '@ctrl/ngx-emoji-mart/picker';

router-outlet {
  display: contents;
}

html, body {
  height: 100%;
  overflow-x: hidden;
}

// TODO: figma, design test.
/*body {
  max-width: 1440px;
  margin: 0 auto;

  border: 5px solid black;
}*/

router-outlet {
  display: none;
}

* {
  outline: none;
}

.fp-timeline {
  height: 0.3em !important;
}


share-button {
  button {
    box-shadow: unset !important;
    border: 1px solid var(--gray-100) !important;
    border-radius: 50% !important;

    .sb-icon {
      box-shadow: unset !important;
    }
  }
}

/* Importing theme files. */
@import './theme/reset';
@import './theme/colors';
@import './theme/fonts';
// @import './theme/icons';
@import './theme/text';
@import './theme/display';
@import './theme/cards';
@import './theme/swal';


/* Cookiebot override */
#CybotCookiebotDialog *, #CybotCookiebotDialogBodyUnderlay * {
  font-size: 14px !important;
}

#CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
  max-height: 10em !important;
}

#CybotCookiebotDialog {
  max-height: calc(100% - 180px) !important;
  width: calc(100% - 40px) !important;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  padding: 0.8em !important;
}

@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog.CybotEdge {
    max-width: 60vw !important;
    min-width: 60vw !important;
  }

  #CybotCookiebotDialog #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
    max-height: 8em !important;
  }

  #CybotCookiebotDialog *, #CybotCookiebotDialogBodyUnderlay * {
    font-size: 12px !important;
  }

  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogHeader {
    width: 145px !important;
  }

  //#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  //  width: 200px!important;
  //}
  //
  //#CybotCookiebotDialog.CybotEdge.CybotMultilevel .CybotCookiebotScrollContainer {
  //  width: calc(100% - 190px - 1.5em)!important;
  //}
}

@media screen and (max-width: 600px) {
  #CookiebotWidget:not(.CookiebotWidget-inactive) {
    bottom: 80px !important;
    right: 10px !important;;
  }

  .whats-app {
    bottom: 20px !important;
    right: 10px !important;

  }
}

.next-level-ribbon {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.next-level-ribbon {
  --r: .8em;
  position: absolute;
  margin-top: 5px;
  border-block: 0.5em solid rgba(0, 0, 0, 0);
  padding-inline: 0.5em calc(var(--r) + 0.25em);
  line-height: 2;
  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%, calc(100% - var(--r)) calc(100% - 0.25em), 100% 50%, calc(100% - var(--r)) 0.25em);
  background: radial-gradient(0.2em 50% at left, rgba(0, 0, 0, 0.6666666667), rgba(0, 0, 0, 0)) border-box, var(--level-primary-700) padding-box;
  width: fit-content;
  //background-color: var(--level-primary-700);
  z-index: 1;
}
