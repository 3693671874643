$tones:950,900,800,700,600,500,400,300,200,100,50;

/* Brand Colors */
@each $tone in $tones {
  .color-level-primary-#{$tone} {
    color: var(--level-primary-#{$tone});
  }

  .bg-level-primary-#{$tone} {
    background-color: var(--level-primary-#{$tone});
  }
}

.color-level-primary {
  color: var(--level-primary);
}

.bg-level-primary {
  background-color: var(--level-primary);
}

@import "levels/default";
@import "levels/pink";
@import "levels/green";
@import "levels/orange";
@import "levels/purple";
@import "levels/blue";
@import "levels/red";
