:root {
  .level-purple, .theme-purple {
    --level-primary-950: #13273E;
    --level-primary-900: #6D137C;
    --level-primary-800: #831098;
    --level-primary-700: #B919E0;
    --level-primary-600: #B919E0;
    --level-primary-500: #D339FC;
    --level-primary-400: #E36FFF;
    --level-primary-300: #ECA5FF;
    --level-primary-200: #F3CCFF;
    --level-primary-100: #F9E6FF;
    --level-primary-50: #FDF3FF;

    --level-primary: #B919E0;
  }
}
