.text-dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-errors {
  p {
    font-size: 14px;
    font-weight: 350;
    letter-spacing: -0.7px;
    color: var(--danger);
  }
}

.one-line {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
