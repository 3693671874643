:root {
  .level-default, .theme-default {
    --level-primary-950: #442404;
    --level-primary-900: #74460F;
    --level-primary-800: #89550A;
    --level-primary-700: #A66E02;
    --level-primary-600: #D19A00;
    --level-primary-500: #FFD200;
    --level-primary-400: #FFE10D;
    --level-primary-300: #FFF141;
    --level-primary-200: #FFFB86;
    --level-primary-100: #FFFFC1;
    --level-primary-50: #FFFFE7;

    --level-primary: #FFD200;
  }
}
