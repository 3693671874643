div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background: var(--danger) !important;

  &:focus {
    box-shadow: 0 0 0 3px rgb(121 121 121 / 11%)!important;
  }
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background: var(--gray-300) !important;
}
