:root {
  --danger: #E6494B;
  --success: #51832D;

  --fp-brand-color: #E6494B !important;
}

$tones: 950, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50;
@each $tone in $tones {
  .color-main-#{$tone} {
    color: var(--main-#{$tone});
  }
}

.color-danger {
  color: var(--danger);
}

.color-success {
  color: var(--success);
}

@import 'mobile/colors';
@import 'mobile/levels';
