.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

.no-border {
  border-color: transparent!important;
}

$widths:50,55,60,65,70,75,80,85,90,95,100;

@each $width in $widths {
  .max-width-#{$width} {
    max-width: #{$width}px;
  }
}

.w-unset {
  width: unset!important;
}

.h-auto {
  height: auto;
}

$lineCounts:1,2,3,4,5;

@each $lineCount in $lineCounts {
  .max-line-#{$lineCount} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: #{$lineCount};
    line-clamp: #{$lineCount};
    -webkit-box-orient: vertical;
  }
}

.border-8 {
  border-radius: 8px!important;
}
