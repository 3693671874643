:root {
  .level-orange, .theme-orange {
    --level-primary-950: #452105;
    --level-primary-900: #80450E;
    --level-primary-800: #A0520C;
    --level-primary-700: #C96A05;
    --level-primary-600: #F58C03;
    --level-primary-500: #FFA70D;
    --level-primary-400: #FFBE34;
    --level-primary-300: #FFDA6E;
    --level-primary-200: #FFEBA7;
    --level-primary-100: #FFF7D3;
    --level-primary-50: #FFFBEC;

    --level-primary: #F58C03;
  }
}
